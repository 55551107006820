@import "node_modules/bootstrap/less/bootstrap";
@import "./spark/spark";
@import "paid";
@import "responsive";

// main navigation
.navbar-static-top {
  margin-top:-100px;
  margin-bottom: 30px;
}

.panel-heading {
  h1, h2, h3, h4, h5, h6 {
    float: left;
    font-size: 15px;
    margin: 5px 0;
  }

  .panel-control {
    float: right;
  }
}

.dropdown-submenu {
    position: relative;
}

.dropdown-submenu .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -1px;
}

a.dropdown-checkbox input[type="checkbox"] {
  display: none;
}

.required:before {
  color: @brand-danger;
  content: '\f069';
  display: inline-block;
  font-family: FontAwesome;
  font-size: 8px;
  margin-right: 5px;
  vertical-align: top;
}

@import "kpi-targets";

.date-range {
  background: #fff;
  -webkit-border-radius: @border-radius-small;
  -moz-border-radius: @border-radius-small;
  border-radius: @border-radius-small;
  border: 1px solid @panel-inner-border;
  cursor: pointer;
  display: inline-block;
  padding: 5px 10px;
}

.date-range.sm {
  font-size: @font-size-small;
}

.table.dataTable.table-condensed > thead > tr .sorting_disabled {
  padding-right: 5px;
}

.fixedHeader-floating {
  font-size: 11px;
}

#seoLinksDataTableDiv {
  margin-top: 20px;
}

.lost-link {
  display:none;
}

#seoLinksDatePickerDiv {
  display: inline;
}

#seoLinksDataTable {
  width: 100% !important;
}

#userSettings input.select2-search__field {
  width: 100% !important;
}

#project-keywords-tracker .multiple-delete {
  cursor: pointer;
}

.multiple-delete {
  position: relative;
  top: 3px;
}

.edit-link-btn {
  margin-right: 3px;
}


@import "./paid/ad-metrics";
@import "./paid/sqr";
@import "./writebrain";
@import "performance-summary";

.table-controls {
  margin-bottom: 10px;
}

.table-th-sm > thead > tr > th {
  font-size: 10px;
  padding-right: 5px!important;
}

.table-th-sm > thead > tr > th[class*="sort"]:after {
  content: "" !important;
}

.input-group-sm .table-col .checkbox {
  padding: 0 10px;
  width: 100%;

  label {
    font-size: @font-size-small;
  }
}

.trim-text-metrics {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 75px;
  line-height: 1em;
}

.trim-text-metrics:hover {
  background-color: #fff;
  color: #000!important;
  position: absolute;
  text-overflow: clip;
  margin-top: 2px;
  max-width: 100%;
}

.modal-sort {
  .modal-body {
    .row {
      margin-bottom: 15px;
    }
  }
}

.input-group .select2-container--default .select2-selection--single {
  border-color: @input-border;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group.input-group-sm {

  .select2-container--default .select2-selection--single {
    height: 30px;
  }
}

.select2-selection--multiple .select2-selection__choice {
  background-color: #455A64 !important;
  color: #fff !important;
  border-radius: 3px !important;
  padding: 7px 12px !important;
}

.token {
    margin: 2px 0 0 2px;
    cursor: default;
    float: left;
    position: relative;
    border-radius: 2px;
}

.bg-teal{
  background-color: #009688;
  border-color: #009688;
  color: #fff;
}

.token .token-label{
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 11px;
  font-size: 12px;
  line-height: 1.6666667;
}

#addKpiTargetForm .input-group {
  margin-bottom: 5px;
}

#editKpiTargetForm .input-group {
  margin-bottom: 5px;
}


.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}

.btn-circle.btn-xs {
  width: 24px;
  height: 24px;
  padding: 3px 5px;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 12px;
}

.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  font-size: 24px;
  line-height: 1.33;
  border-radius: 35px;
}

// Margin helpers
.top5 {
  margin-top: 5px;
}

.top10 {
  margin-top: 10px;
}

.top15 {
  margin-top: 15px;
}

.top20 {
  margin-top: 20px;
}

.top30 {
  margin-top: 30px;
}
.bottom5 {
  margin-bottom: 5px;
}
.bottom10 {
  margin-bottom: 10px;
}
.bottom15 {
  margin-bottom: 15px;
}
.bottom20 {
  margin-bottom: 20px;
}
.bottom30 {
  margin-bottom: 30px;
}
.edit-tooltip{
  font-size: 14px;
  vertical-align: top;
  margin-left: 5px;
  padding: 5px;
  background-color: #eee;
  border-radius: 5px;
  display:none;
  cursor: pointer ;
}
span:hover > .edit-tooltip, label:hover > .edit-tooltip{
  display: inline-block;
}
#profile h3{
  background: #eee;
  padding: 10px 15px;
}
.left15{margin-left:15px;}
.left10{margin-left:10px;}

.spark-settings-stacked-tabs{
  li.active a > i {
    color: #3097D1;
  }
   
  ul.nav-sub-spark {
    li > a {
      padding-left: 35px;
      border-bottom: 1px solid #e4ecf2 !important;
    }
  }
}

#userKpi tbody tr .quarter-dropdown:before{
  top:50%;
  margin-right:5px;
  width: 9px; height: 16px;
  margin-top: -8px;
  font: 16px fontawesome;
  color: #999;
  content: "\f0d7";
  transition: all .3s ease;
}
td.quarter-dropdown{
  cursor: pointer;
  display: flex;
  align-items: center;
}
#userKpi tbody tr .open:before{
  transform: rotate(-180deg);
  color: #333;
}