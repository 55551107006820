/* modal sorting less  >> */
.modal-sort {
   .modal-header {
      p {
         font-weight: 500;
         font-size: 15px;
      }
      padding: 15px 15px 0;
  }

   .modal-body {
      .wrapper {
         font-size: 14px;
         margin-bottom: 10px;
         select:nth-child(1) { min-width: 185px }
         select {
            padding: 3px 5px;
            margin-right: 10px;
            border: 1px solid #e0dada;
            min-width: 100px;
         }
      }
      .btn {
         margin-left: 5px;
      }
   }
}

.swal_sort {
   h2 {
      font-size: 26px;
      margin-bottom: 10px;
   }
   p { font-weight: 500; }
}