@import "../modal-sort.less";

#panel-ad-metrics {
  ul.nav-tabs {
    li.active {
      a, a:hover, a:focus {
        background-color: #fff;
      }

      a:hover {
        background-color: rgba(238, 238, 238, 0.36);
      }
    }
  }

  .tab-content {
    margin-top: 20px;
  }

  .row-ad-metrics-buttons {
    margin-bottom: 20px;
  }

  .table-ad-metrics {
    width: 100%!important;
    font-size: @font-size-small;
  }
}

#panel-ad-metrics {
  overflow: inherit;

  .panel-body {
    min-height: 725px
  }
}

.table-ad-metrics {
  &.fixedHeader-floating {
    font-size: @font-size-small;
  }
}

.ad-img-box {
  width: 65px;
  margin: 0 auto;
  height: 30px;
  transition: .15s;
  overflow-y: hidden;
  position: relative;
}

.table-controls {
  margin-top: 20px;
}

.ad-img-box:hover {
  overflow-y: inherit;
  transform: scale(1.2, 1.2);
  transform-origin: center;
  z-index: 1;
}

ul.metrics-list {
  list-style-type: none;
  padding-left: 0;

  li {
    display: inline-block;
    background: #f5f5f5;
    padding: 3px 10px;
  }
}

.table-col {

  li:hover {
    background: rgba(222, 222, 222, 0.25);
  }

  .checkbox {
    padding: 0 10px;
    margin: auto;

    label {
      width: 100%;
    }
  }
}

.ads-container {
  position: relative;
  width: 100%;

  .ad-metric-img {
    height: 20px;
    max-width: 100px;
    overflow: hidden;
    margin: auto;
    position: relative;

    img {
      margin: auto;
      transition: transform .2s;
    }

    &:hover img {
      position: fixed;
      right: 50%;
      left: 50%;
      top: 50%;
      box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
      transform: translate(-50%, -50%) scale(1.2);
      height: auto;
      width: auto;
      max-width: 400px;
    }
  }

  a {
    position: absolute;
    left: 0;
    top: 0;
    background: #f5f5f5;
    padding: 0 3px;
    font-size: 13px;
  }

  &:hover a { background: #d6d5d5 }
}

#btn-table-facebook-sort { display: none }
#btn-table-adwords-sort{ z-index: 0 !important;}

