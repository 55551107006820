#kpitable, #kpi-user-table {
  font-size: 11px;
  width: 100% !important;
}

#kpitable .bucketComparison {
  background: rgb(217, 237, 247);
  text-align: right;
  width: 50px;
}

#kpitable .borderLeft {
  border-left: 2px solid grey;
}

#kpitable td,
#kpi-user-table td {
  padding: 0 8px;
  vertical-align: top;
}

table.kpi-table.table-bordered.dataTable .linearStyle {
  background: rgb(217, 237, 247);
  font-size: 11px;
  font-weight: bold;
  text-align: right;
}

#kpiTarget .row {
  margin-bottom: 10px;
}

#kpiTarget .tab-content .tab-pane {
  padding-top: 20px;
}

.kpi-table .neutral,
.kpi-table .kpi-month-metric {
  font-weight: bold;
  text-align: right;
}

.kpi-table .quarter-end {
  border-right: 2px solid #ccc;
}

.kpi-table.table-bordered.dataTable .linearStyle.negative,
.kpi-table .neutral.negative,
.kpi-table .kpi-month-metric.negative {
  color: red;
}

.kpi-table .kpi-month-metric.best {
  border-right: 2px solid @brand-success;
  background-color: @state-success-bg;
}

.kpi-table.table-bordered.dataTable .linearStyle.positive,
.kpi-table .neutral.positive,
.kpi-table .kpi-month-metric.positive {
  color: green;
}

.kpi-table .kpi-quarter-target {
  background-color: #f0f0f0;
  color: #000;
  font-weight: bold;
  text-align: right;
}

.kpi-table .kpi-quarter-target.negative {
  color: #fff;
  background-color: #d52424;
}

.kpi-table .kpi-quarter-target.positive {
  color: #fff;
  background-color: green;
}
