@media only screen and (max-width: 1200px) {
  .container {

    .panel.panel-default {
      width: 100%;
    }

    > .row .col-md-12 {
      margin-top: 10px;
      padding: 0;
      overflow:auto;
    }
  }

  #container-leads {

    // leads
    #row-lead-filters {
      display: flex;
      flex-flow: row wrap;
    }
  }

}
@media (max-width: 991px) {
  .navbar-header {
    float: none;
  }
  .navbar-left,.navbar-right {
    float: none !important;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
    display: none!important;
  }
  .navbar-nav {
    float: none!important;
    margin-top: 7.5px;
  }
  .navbar-nav>li {
    float: none;
  }
  .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collapse.in{
    display:block !important;
    overflow:auto !important;
  }
  ul.dropdown-menu{
    position: relative;
    width: 100%;
    text-align: left;
  }
  #filterRow{
    display: flex;
  }
}
@media only screen and (max-width: 800px) {

  .container {
    > .row .col-md-12 .form-inline{
      display: flex;
      justify-content: space-evenly;
      width: 100%;
    }
  }
}
