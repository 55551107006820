.positive {
  color: green;
}

.negative {
  color: red;
}

.default {
  color: black;
}

.heading-title {
  font-weight: bold;
}