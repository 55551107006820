@import "../modal-sort.less";

.input-group-addon.text-select-fix {
  border: none;
  padding-left: 0px;
  padding-right: 0px;
  width: 0px;
}

#table-sqr {
  font-size: @font-size-small - 1;
  width: 100%;
}

#table-sqr_wrapper {
  .dataTables_info,
  .dataTables_paginate ul.pagination {
    font-size: @font-size-small;
  }
}
