.content-tbl-cellpad .table > tbody > tr > td { padding: 3px 8px; }
.td-parent {  max-width: 150px;  }
.hover-tr { transition: .15s }
.hover-tr:hover td { box-shadow: 0px 0px 0px 0.1px #b7b7b7; }
.hover-tr:hover, .hover-title:hover { 
    background-size: 100% 100%;
    transform: scale(1.03, 1.3);
    transform-origin: center;
    background: rgb(255, 255, 255);
    box-shadow: 0 0 20px 3px #ddd; }
.no-bt-border { border-top: 0 !important; border-bottom: 0 !important; text-align: right; }
.td-parent { border-bottom: 0 !important; }
.td-parent label.parent-channel { padding: 2px 10px; font-size: 11px; display: block; cursor: pointer; }
.tg-overview { display: inline-block; margin-left: 40px;}
.tg-overview label { padding-left: 10px }
.no-margin { margin: 0 }
.col-name {
    padding-left: 10px;
    font-size: 11px;
    font-weight: 500;
}
.parent-channel:hover { background: rgba(238, 238, 238, 0.8); }
.parent-channel[data-toggle="tooltip"]:hover ~ .tooltip { transform: scale(1, .8); }
.hover-title { background: rgba(238, 238, 238, 0.4); }