.trim-text-metrics.wb-path {
  max-width: 200px;
}

.trim-text-metrics.wb-title {
  max-width: 125px;
}

.trim-text-metrics.wb-path:hover,
.trim-text-metrics.wb-title:hover {
  margin-top: 3px;
}
